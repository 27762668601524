import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer/Footer";
import TermsBanner from "../Components/Terms/TermsBanner";
import TermsContent from "../Components/Terms/TermsContent";
import { Box } from "@mui/material";
import ChatButtons from "../Components/ChatButtons";

const Terms = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <Box>
         {/* <Navbar /> */}
         <TermsBanner />
         <TermsContent />
         {/* <ChatButtons />
         <Footer /> */}
      </Box>
   )
}

export default Terms