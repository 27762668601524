import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Voltage from "../../Images/voltage.png";
import Battery from "../../Images/smart.png";
import Efficiency from "../../Images/efficiency.png";
import Vission from "../../Images/OurVision.png";
import Warrenty from "../../Images/warranty.png";
import IP65 from "../../Images/ip65.png";
import Visibility from "../../Images/visibility.png";


const Logosgeneration = () => {
  return (
    <React.Fragment>
      <Box>
        <Container maxWidth="xl" className="">
          <Grid
            className="tt_Logo_inner tt-resig-logo"
            container
            spacing={2}
            sx={{ alignItems: { xs: "center", md: "center" } }}
          >
            <Grid className="LogoInner" item xs={12} md={3} sx={{ marginTop:"10px"}}>
              <img src={Battery} alt="solar system for home price in Pakistan" width="55px" />
              <Typography variant="h2">Smart Flow</Typography>
              <Typography >
                AI software that manages energy optimally to lower electricity
                bills and maximize power availability.
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}  sx={{ marginTop:"10px"}}>
              <img src={Voltage} alt="Home Solar Panel Installation" width="55px" />
              <Typography variant="h2">High Voltage Battery</Typography>
              <Typography >
              High Voltage, Long Life, Energy Dense Lithium-ion Battery Pack with fast charge and discharge capacity and a very long battery life
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}  sx={{ marginTop:"10px"}}>
              <img src={Efficiency} alt="Solar Power Systems for Homes" width="55px" />
              <Typography variant="h2">Highly Efficient</Typography>
              <Typography >
              High Voltage design resulting in highly efficient system with few power losses
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}  sx={{ marginTop:"-30px"}}>
              <img src={Vission} alt="Residential Solar Energy Solutions" width="55px" />
              <Typography variant="h2">Power Capability</Typography>
              <Typography >
              Full power capability in backup mode
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}>
              <img src={Warrenty} alt="Home Solar Power System" width="55px" />
              <Typography variant="h2">Extended Warranties</Typography>
              <Typography >
              Highly durable system with warranties up to 10 years
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}>
              <img src={IP65} alt="Solar Panel Cost for Homes" width="55px" />
              <Typography variant="h2">IP65 Design</Typography>
              <Typography >
              Dustproof, waterproof, noiseless and fanless compact design with both indoor and outdoor installation flexibility
              </Typography>
            </Grid>
            <Grid className="LogoInner" item xs={12} md={3}>
              <img src={Visibility} alt="Best Solar Panels for Residential Use" width="55px" />
              <Typography variant="h2">Visibility & Control</Typography>
              <Typography >
              Advanced touchscreen energy console provides an instant picture of the energy produced and consumed with control of smartflow actions.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Logosgeneration;
