import React from "react";
import Market from "../../Images/bg/9.jpg";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const MarketGlobe = () => {
   return (
      <React.Fragment>
         <Box className="solar-wrapper">
            <Box className="solar-wrapper-img inverse">
               <img width="100%" src={Market} alt="solar companies in pakistan" />
            </Box>
            <Box className="tt-solar-wrapper tt-solar-inner tt-InnerSolar-Wrapper">
               <Typography variant="h2">
                  A Solar Answer for Every Market in the World
               </Typography>
               <Typography>
                  Solar power has been adopted globally for multiple reasons,
                  including grid independence, adoption of clean energy, net-metering,
                  and lower electricity bills. For countries in the developing world
                  with un-stable grids, such as Pakistan, India, Bangladesh, Nigeria &
                  various countries in Africa, the main purpose of Solar Solutions is
                  to provide continuous electricity amidst frequent power outages.
                  Solectic solves the problem of load-shedding along with the
                  benefits of solar solutions that homes and businesses enjoy in the
                  West.
               </Typography>
               <Box className="tt-BtnMain">
                  <Link to={"/contact-us"}>
                     <button className="ttBtn solarBtn">Contact Us</button>
                  </Link>
               </Box>
            </Box>
         </Box>
      </React.Fragment>
   );
};

export default MarketGlobe;
