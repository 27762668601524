import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { allPackages } from "../PackagesData.js"

const ResidentialPackages = () => {

   const [activeTab, setActiveTab] = useState(allPackages[0].name);

   const handleTabClick = (tab) => {
      setActiveTab(tab);
   }

   return (
      <React.Fragment>
         <Box className="mainPadding residential-packages">
            <Container maxWidth='lg'>
               <Typography variant='h2' sx={{ textAlign: 'center' }} className='mission_Title'>Residential Packages</Typography>

               <Box className="packages-tabs">
                  {
                     allPackages.map((item, index) => (
                        <button key={index} className={activeTab === item.name ? 'tab-btn active' : 'tab-btn'} onClick={() => handleTabClick(item.name)}>{item.name}</button>
                     ))
                  }
               </Box>

               {
                  allPackages?.filter((item) => item.systemCapacity === activeTab)?.map((item, index) => (
                     <Box className="package-content" key={index}>
                        <Box className="package-header">
                           <h3>Package {item.name}</h3>
                           <p>{item.subHeading}</p>
                           <h4>{item.loadDescription}</h4>
                        </Box>
                        <Box className="package-details">
                           <Grid container>
                              {/* Left */}
                              <Grid item xs={12} md={6}>
                                 <Box className="info-box">
                                    <p>System Rated Capacity:</p>
                                    <h4>{item.systemCapacity}</h4>
                                 </Box>
                                 <Box className="info-box">
                                    <p>Smart Energy Storage Pack:</p>
                                    <h4>{item.energyStorage}</h4>
                                 </Box>
                                 <Box className="info-box">
                                    <p>Rated Solar Power:</p>
                                    <h4>{item.solarPower}</h4>
                                 </Box>
                                 {
                                    item.colorsAvailable &&
                                    <Box className="info-box">
                                       <p>System Colors Available:</p>
                                       <h4>{item.colorsAvailable}</h4>
                                    </Box>
                                 }
                              </Grid>
                              {/* Right */}
                              <Grid item xs={12} md={6}>
                                 <Box className="info-box">
                                    <p>Dimensions:</p>
                                    <h4>{item.dimentions}</h4>
                                 </Box>
                                 <Box className="info-box">
                                    <p>Net Metering:</p>
                                    <h4>{item.netMetering}</h4>
                                    <p></p>
                                 </Box>
                              </Grid>
                              {
                                 item.note &&
                                 <p>{item.note}</p>
                              }
                           </Grid>
                        </Box>
                     </Box>
                  ))
               }
            </Container>
         </Box>
      </React.Fragment>
   )
}

export default ResidentialPackages