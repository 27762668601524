import React, { useEffect } from 'react'
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer/Footer"
import CommercialBanner from '../Components/CommercialComponents/CommercialBanner'
import FirstContent from '../Components/CommercialComponents/FirstContent'
import FinancialIncentive from '../Components/CommercialComponents/FinancialIncentive'
import NetMetering from '../Components/CommercialComponents/NetMetering'
import Rules from '../Components/CommercialComponents/Rules'
import Qualify from '../Components/CommercialComponents/Qualify'
import ChatButtons from '../Components/ChatButtons'
import CustomerSupport from '../Components/ContactComponents/CustomerSupport'
import SEO from '../Components/Seo/Seocomponent'


const Commercial = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <>
         {/* <Navbar /> */}
         <SEO
         title="Commercial Solar Panels system | Commercial solar energy solutions"
         description="We are a commercial solar panel installer and service provider based out of Islamabad Pakistan. Our range of services include design, installation and maintenance for commercial solar energy systems. Get Free Online Quotes."
         keywords="Commercial Solar Panels system,commercial solar panel system cost"
         link="https://solecticengineering.com/commercial"
         />
         <CommercialBanner />
         <FirstContent />
         <FinancialIncentive />
         <NetMetering />
         <Rules />
         <Qualify />
         {/* <CustomerSupport />
         <ChatButtons />
         <Footer /> */}
      </>
   )
}

export default Commercial