import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer/Footer'
import Resbanner from '../Components/ResidentialComponents/Resbanner'
import Generation from '../Components/ResidentialComponents/Generation'
import Logosgeneration from '../Components/ResidentialComponents/Logosgeneration'
import ContactFooter from '../Components/ContactComponents/ContactFooter'
import ResidentialPackages from '../Components/ResidentialComponents/ResidentialPackages'
import CustomerSupport from '../Components/ContactComponents/CustomerSupport'
import ChatButtons from '../Components/ChatButtons'
import SEO from '../Components/Seo/Seocomponent'

const Residentials = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <>
      <SEO
      title="Residential solar system in Lahore | Solectic Engineering"
      description="Solectic is a residential solar panel system company based in Lahore. We offer affordable solar power solutions for homes, offices and industries."
      keywords="residential solar system ,Solar system for home price in pakistan"
      link="http://solecticengineering.com/residential"
      />
         {/* <Navbar /> */}
         <Resbanner />
         <Logosgeneration />
         <ResidentialPackages />
         {/* <CustomerSupport />
         <ChatButtons />
         <Footer /> */}
      </>

   )
}

export default Residentials