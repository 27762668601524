import React, { useState } from 'react'
import BG from '../../Images/bg/7.jpg'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

const Rules = () => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Box className="solar-wrapper">
        <Box className="solar-wrapper-img inverse">
          <img width="100%" src={BG} alt="Commercial Solar Installation" />
        </Box>
        <Box className="tt-solar-wrapper tt-InnerSolar-Wrapper">
          <Typography variant="h2">RULES AND RESTRICTIONS IMPACTING INCENTIVE PROGRAMS</Typography>
          <Typography>
            Unleashing the Potential of Net Energy Metering Programs and Empowering You to Harness Sustainable Energy, Save Costs, and Drive a Greener Tomorrow.
          </Typography>
          <Box className="tt-BtnMain">
            <button className="ttBtn solarBtn" onClick={() => setOpen(true)}>Learn More</button>
          </Box>
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">RULES AND RESTRICTIONS IMPACTING INCENTIVE PROGRAMS</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            Net Energy Metering or NEM programs ar the foremost wide accessible utility programs, currently offered in varied forms across thirty-nine states. NEM programs alter utility customers with star generation to earn asking credit for excess electricity they manufacture over what they use. At the tip of a customer’s 12-month asking amount, any surplus electricity generated is “trued-up” at a good market price (net surplus compensation), that is predicated on a rolling average of the market rate for energy. In Calif., wherever over ninetieth of customer-owned star sites qualify for NEM credits, market rates hover between $0.02 to $0.03 per kWh. There ar permutations to Calif. NEM programs and the way they’re meted out, including: Virtual internet Energy Metering (VNEM) VNEM may be a Calif. utility tariff accessible to multi-tenant properties. It permits the possessor to allot a star PV system’s edges to tenants across multiple units. Otherwise, this functions an equivalent because the NEM program. NEM aggregation (NEMA) Allows eligible customers to combination the electrical load from multiple meters, and NEM credits may be shared among all the property that’s connected, adjacent or contiguous to the generation facility. so as to use NEMA, the client should be the only owner, renter or renter of the properties. NEM electric cell (NEMFC) Fuel cells victimization non-renewable fuels and meeting gas (GHG) emissions standards ar eligible for the NEM electric cell Program. NEMFC credits ar applied at the generation portion of the retail rate. There is a national information of State Incentive Programs for Renewables and potency from North Carolina Clean Energy searchable by state or code. The Calif. technical school University (Cal Poly) broached state monetary incentive programs to create an oversized scale ground mount star on twenty acres of unused field lands. The star PV offsets twenty five p.c of the university’s annual electricity desires.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Rules