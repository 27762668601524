// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Home from './Pages/Home';
import { Contact } from './Pages/Contact';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Residentials from './Pages/Residentials';
import Commercial from './Pages/Commercial';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer/Footer';
import ChatButtons from './Components/ChatButtons';
import CustomerSupport from './Components/ContactComponents/CustomerSupport';

const PageLayout = () => {
   return (
      <>
         <Navbar />
         <Outlet />
         <CustomerSupport />
         <ChatButtons />
         <Footer />
      </>
   );
};

const App = () => {
   return (
      <Router>
         <Routes>
            <Route element={<PageLayout />}>
               <Route path="/" element={<Home />} />
               <Route path="/contact-us" element={<Contact />} />
               <Route path="/about-us" element={<About />} />
               <Route path="/projects" element={<Projects />} />
               <Route path="/residential" element={<Residentials />} />
               <Route path="/commercial" element={<Commercial />} />
               <Route path="/terms-and-conditions" element={<Terms />} />
               <Route path="/privacy" element={<Privacy />} />
            </Route>
         </Routes>
      </Router>
   );
};

export default App;
