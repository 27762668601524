import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Mision from "../../Images/OurMission.png";
const Misson = () => {
  return (
    <React.Fragment>
      <Box className="mainPadding">
        <Container maxWidth="md" className="vision">
          <Grid
            className="tt-mission"
            container
            spacing={2}
          >
            <Grid item xs={12} md={2} className="missionCenter">
              <img src={Mision} alt="solar companies in islamabad" />
            </Grid>
            <Grid item xs={12} md={10} className="missionCenter">
              <Typography className="missionTitle" variant="h2">
                Our Mission
              </Typography>
              <Typography variant="p">
                To make clean energy universally available by building a
                distributed and intelligent solar and energy storage grid,
                managed via the Internet, across the world.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Misson;
