import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Ashar from "../../Images/ceo.jpeg";
const NadeemAkram = () => {
  return (
    <React.Fragment>
      <Box className="mainPadding">
        <Container maxWidth="xl" className="tt-ashar">
          <Grid
            className="tt-mission"
            container
            spacing={2}
            sx={{ alignItems: { xs: "center", md: "center" } }}
          >

            <Grid item xs={12} md={5} className="missionCenter">
              <img src={Ashar} alt="Best Solar Company in Pakistan" className="person-img" />
            </Grid>
            <Grid item xs={12} md={7} className="missionCenter">
              <Typography className="mission_Title" variant="h2">
                Nadeem Akram
              </Typography>
              <Typography className="missionTitle" variant="h2">
                CEO
              </Typography>
              <Typography className="missionCont" variant="p">
              Nadeem Akram is an accomplished professional and a forward-thinking leader who serves as the Founder and Executive Chairman of Solectic Engineering Solutions (SMC-PVT) LTD. With his extensive background in electrical engineering and his strong dedication to sustainable energy solutions, Nadeem has significantly influenced the trajectory of Solectic Engineering Solutions (SMC-PVT) LTD.

Nadeem's visionary outlook goes beyond the immediate objectives of the company. He envisions a future where sustainable energy is not just a choice but a universal standard. His leadership philosophy focuses on encouraging innovation, promoting environmental stewardship, and delivering tangible value to clients through advanced technologies.<br></br><br></br>

Along with his role at Solectic Engineering Solutions (SMC-PVT) LTD., Nadeem Akram remains actively engaged in industry advancements and collaborations. He takes a hands-on approach to ensure that the company remains at the forefront of industry trends, standards, and regulatory developments.<br></br><br></br>

Nadeem Akram's journey from Hitech to the helm of Solectic Engineering Solutions (SMC-PVT) LTD. is a testament to his commitment to academic excellence, innovation, and the evolution of sustainable energy solutions. His constructive leadership style has been instrumental in shaping the company's identity and ensuring its continued success in the dynamic and ever-changing field of solar engineering.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NadeemAkram;
