import { Box, Grid, Typography } from '@mui/material'
import React, { useCallback, useRef } from 'react'
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgRotate from 'lightgallery/plugins/rotate';

function SingleProjectGallery({ title, region, type, size, co2, images }) {

   const allImages = images?.map(img => {
      return ({
         src: img,
         thumb: img
      })
   })

   const lightGallery = useRef(null);

   const openGallery = useCallback(() => {
      lightGallery.current.openGallery();
   }, []);

   const onInit = useCallback((detail) => {
      if (detail) {
         lightGallery.current = detail.instance;
      }
   }, []);

   return (
      <>
         <Grid className="LogoResinner" item xs={12} md={6}>
            <Box className="LogoResinner-img" onClick={openGallery}>
               <img src={images[0]}  alt="Premier Solar Solutions Pakistan" />
            </Box>
            <Box className="industrial_inner" onClick={openGallery}>
               <Typography variant="h5">{title}</Typography>
               <Typography>
                  Region: <b>{region}</b>
               </Typography>
               <Typography>
                  Type of System: <b>{type}</b>
               </Typography>
               <Typography>
                  System Size: <b>{size}</b>
               </Typography>
               <Typography>
                  Annual CO2 Reduction: <b>{co2}</b>
               </Typography>
            </Box>
         </Grid>

         <LightGallery
            speed={500}
            dynamic={true}
            dynamicEl={allImages}
            onInit={onInit}
            plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}
         />
      </>
   );
}

export default SingleProjectGallery