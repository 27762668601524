import { Box, Container } from '@mui/material'
import React from 'react'

const ContactMap = () => {
   return (
      <Container maxWidth="xl" sx={{ marginTop: '30px' }}>
         <Box>
            <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=Office+19+1st+Floor+Swiss+Centre+D12+Markaz%2C+Islamabad&t=&z=13&ie=UTF8&iwloc=&output=embed"  width="100%" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

         </Box>
      </Container >
   )
}

export default ContactMap