export const allProjects = [
  {
    id: 1,
    title: "PCSIR Phase 2",
    region: "Central",
    type: "Hybrid System",
    size: "5 kW",
    co2: "418.Tonnes",
    images: ["/images/Project1/1.jpg", "/images/Project1/2.jpg", "/images/Project1/3.jpg", "/images/Project1/4.jpg"]
  },
  {
    id: 2,
    title: "State Life Phase 2",
    region: "Central",
    type: "On-Grid",
    size: "5 kW",
    co2: "418.Tonnes",
    images: ["/images/Project2/1.jpg", "/images/Project2/2.jpg", "/images/Project2/3.jpg", "/images/Project2/4.jpg"]
  },
  {
    id: 3,
    title: "Bharia Town",
    region: "Central",
    type: "On-Grid",
    size: "5 kW",
    co2: "418.Tonnes",
    images: ["/images/Project3/1.jpg", "/images/Project3/2.jpg", "/images/Project3/3.jpg", "/images/Project3/4.jpg"]
  },
  {
    id: 4,
    title: "Lake City",
    region: "Central",
    type: "Site",
    size: "5 kW",
    co2: "418.Tonnes",
    images: ["/images/Project4/1.jpg", "/images/Project4/2.jpg", "/images/Project4/3.jpg", "/images/Project4/4.jpg"]
  },
  {
    id: 5,
    title: "Sukh Chain",
    region: "Central",
    type: "On-Grid",
    size: "7 kW",
    co2: "418.Tonnes",
    images: ["/images/Project5/1.jpg", "/images/Project5/2.jpg", "/images/Project5/3.jpg", "/images/Project5/4.jpg"]
  },
  {
    id: 6,
    title: "EME Society",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project6/1.jpg", "/images/Project6/2.jpg", "/images/Project6/3.jpg", "/images/Project6/4.jpg"]
  },
  {
    id: 7,
    title: "Nasheman e Iqbal",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project7/1.jpg", "/images/Project7/2.jpg", "/images/Project7/3.jpg", "/images/Project7/4.jpg", "/images/Project7/5.jpg"]
  },
  {
    id: 8,
    title: "EME Society",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project8/1.jpg", "/images/Project8/2.jpg", "/images/Project8/3.jpg", "/images/Project8/4.jpg", "/images/Project8/5.jpg"]
  },
  {
    id: 9,
    title: "Valencia Town",
    region: "Central",
    type: "Hybrid System",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project9/1.jpg", "/images/Project9/2.jpg", "/images/Project9/3.jpg", "/images/Project9/4.jpg", "/images/Project9/5.jpg"]
  },
  {
    id: 10,
    title: "Sarwar Colony Cantt",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project10/1.jpg", "/images/Project10/2.jpg", "/images/Project10/3.jpg", "/images/Project10/4.jpg"]
  },
  {
    id: 11,
    title: "DHA Phase 6",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project11/1.jpg", "/images/Project11/2.jpg", "/images/Project11/3.jpg", "/images/Project11/4.jpg"]
  },
  {
    id: 12,
    title: "Samanabad",
    region: "Central",
    type: "On-Grid",
    size: "10 kW",
    co2: "418.Tonnes",
    images: ["/images/Project12/1.jpg", "/images/Project12/2.jpg", "/images/Project12/3.jpg", "/images/Project12/4.jpg", "/images/Project12/5.jpg"]
  },
  {
    id: 13,
    title: "Nabi Pura Sheikhupura",
    region: "Central",
    type: "On-Grid",
    size: "15 kW",
    co2: "418.Tonnes",
    images: ["/images/Project13/1.jpg", "/images/Project13/2.jpg", "/images/Project13/3.jpg", "/images/Project13/4.jpg", "/images/Project13/5.jpg"]
  },
]