import { Box, Typography } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const CommercialBanner = () => {
  return (
    <React.Fragment>
      <Box className="bannerInner">
        <img src={About} width="100%" alt="Commercial Solar Panels system" />
        <Box className="tt-banerInner banner_inner">
          <h1>30 kW to 3+ MW Smart Solar Solutions for Commercial & Industrial Sectors</h1>
          <Typography>
            Get Energy Control of Your Business with Enhanced Operational Efficiency
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CommercialBanner;
