import { Box, Container, Typography } from "@mui/material";
import React from "react";
import LogoResidential from "./LogoResidential";

const Residential = () => {
  return (
    <React.Fragment>
      <Box className="mainPadding bgLight">
        <Container maxWidth="md" className="">
          <Box className="tt-overview">
            <Typography variant="h2">Residential Projects</Typography>
            <Typography className="tt-pt">
              Solectic Engineering provides different residential systems according to
              your needs and requirements. Our Smart Solar Systems have been
              installed in 150+ residential sites all across Pakistan.
            </Typography>
          </Box>
        </Container>
        {/* <LogoResidential /> */}
      </Box>
    </React.Fragment>
  );
};

export default Residential;
