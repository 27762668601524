import { Box, Typography } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const ContactBanner = () => {
  return (
    <React.Fragment>
      <Box className="bannerInner">
        <img src={About} width="100%" alt="Solectic Contact Number" />
        <Box className="tt-banerInner banner_inner">
          <Typography variant="h1">
            We are Here to Help
          </Typography>
          <Typography>
            Get in Touch With Us
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ContactBanner;
