import React from 'react'
import { Box, Container, Grid, Typography } from "@mui/material"
import ContactInfoPic from "../../Images/ContactInfoPic.png"
import WhatsApp from "../../Images/whatsApp.svg"
import Email from "../../Images/email-icon.svg"

const ContactInfo = () => {
   return (
      <React.Fragment>
         <Box className="tt-mission1 contact-info">
            <Container maxWidth="xl">
               <Grid
                  className=""
                  container
                  sx={{ alignItems: { xs: "center", md: "center" } }}
                  spacing={4}
               >
                  <Grid item md={6} className="contact-info-img">
                     <img src={ContactInfoPic} alt="Solectic Help Desk Number"  className='support-img' />
                  </Grid>
                  <Grid item md={4} xs={12}>
                     <Typography variant='p'>Please contact us at
</Typography>
                     <a href="mailto:info@solecticengineering.com" className='contact-info-email'>
                        <img src={Email} alt="Solectic Contact Email"  />
                        info@solecticengineering.com
                     </a>
                     <a href="https://wa.me/+923065659733" className="whatsapp-number">
                        <img src={WhatsApp} alt="Solectic Phone Number"  />
                        +923065659733
                     </a>
                     <a href="https://wa.me/+923344859556" className="whatsapp-number">
                        <img src={WhatsApp} alt="Solectic Support Number"  />
                        +923344859556
                     </a>
                  </Grid>
               </Grid>
            </Container>
         </Box>

      </React.Fragment>
   )
}

export default ContactInfo