import { Box, Typography } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const ProjBanner = () => {
  return (
    <React.Fragment>
      <Box className="bannerInner">
        <img src={About} width="100%" alt="Solar Panel System Price In Pakistan" />
        <Box className="tt-banerInner banner_inner">
          <Typography variant="h1">
            Smart Living with Next Generation Advanced Solar Energy System
          </Typography>
          <Typography>
            Switch to Solar for Clean, Green and Affordable Energy
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ProjBanner;
