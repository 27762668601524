export const allPackages = [
  {
    name: "30 kW",
    systemCapacity: "30 kW",
    energyStorage: "3x10 kWh",
    solarPower: "30.2 kW",
    dimentions: "1893.6mm H x 2641.65mm W x 233mm D Backup Time @90% DoD: 60 min @ 90% Peak Load",
    netMetering: "Enabled",
    subHeading: "This system has been sized for a luxury house of ten to fifteen bedrooms. The load this system can handle even in an outage are:",
    loadDescription: "Twelve 1-ton inverter ACs or nine 1.5-ton inverter ACs, fridges, LCD/LED TVs and a number of lights and fans.",

  },
  {
    name: "20 kW",
    systemCapacity: "20 kW",
    energyStorage: "2x10 kWh",
    solarPower: "20.3 kW",
    dimentions: "1959.35mm H x 1711.16mm W x 233mm D Backup Time @90% DoD: 60 min @ 90% Peak Load",
    netMetering: "Enabled",
    subHeading: "This system has been sized for a large house of five to seven bedrooms. The load this system can handle even in an outage are:",
    loadDescription: "Eight 1-ton inverter ACs or six 1.5-ton inverter ACs, fridges, LCD/LED TVs and a number of lights and fans.",
  },
  {
    name: "10 kW",
    systemCapacity: "10 kW",
    energyStorage: "10 kWh",
    solarPower: "10.4 kW",
    dimentions: "1959.35mm H x 790mm W x 233mm D Backup Time @90% DoD: 60 min @ 90% Peak Load",
    netMetering: "Enabled",
    colorsAvailable: "White, Slate",
    subHeading: "This system has been sized for a medium sized house of three to four bedrooms. The load this system can handle even in an outage are:",
    loadDescription: "Four 1-ton inverter ACs or three 1.5-ton inverter AC, fridges, LCD/LED TV and a number of lights and fans.",
  },
  {
    name: "5 kW",
    systemCapacity: "5 kW",
    energyStorage: "5 kWh",
    solarPower: "5000 W",
    dimentions: "921.12mm H x 790mm W x 233.37mm D Backup Time @90% DoD: 60 min @ 90% Peak Load",
    netMetering: "Enabled*",
    colorsAvailable: "White, Slate",
    subHeading: "This system has been sized for a small two to three bedroom house. The load this system can handle even in an outage are:",
    loadDescription: "Two 1-ton inverter AC or one 2-ton inverter AC, fridge, LCD/LED TV and a number of lights and fans.",
    note: "*For single phase inverters Net metering licensing is dependent on local DISCO/NEPRA rules.",
  },
  {
    name: "3 kW",
    systemCapacity: "3 kW",
    energyStorage: "5 kWh",
    solarPower: "3000 W",
    dimentions: "921.12mm H x 790mm W x 233.37mm D Backup Time @90% DoD: 110 min @ 90% Peak Load",
    netMetering: "Enabled*",
    subHeading: "This system has been sized for a small one or two bedroom house/apartment. The load this system can handle even in an outage are:",
    loadDescription: "One 1-ton inverter AC, fridge, LCD/LED TV and a number of lights and fan",
    note: "*For single phase inverters Net metering licensing is dependent on local DISCO/NEPRA rules.",
  }
]