import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const TermsContent = () => {
   return (
      <Box className="mainPadding terms">
         <Container maxWidth="lg">
            <Typography variant="h2" className="mission_Title" sx={{ textAlign: "center" }}>
               Terms And Conditions of Solectic Engineering Solutions (SMC-PVT) LTD.
            </Typography>
            <p>
               Accessing the Solectic Engineering Solutions (SMC-PVT) LTD website means you are bound by its Terms and Conditions of Use, applicable laws and regulations. If you disagree with any of these terms, you are not allowed to use or access this site. The materials on this site are protected by relevant copyright and trademark law.
            </p>
            <p>
               We grant permission to temporarily download one copy of the materials (data or programming) on the site for personal and non-commercial use only. This is a license and not an exchange of title. Under this license, you must not modify or copy the materials, use the materials for any commercial or public use, attempt to decompile or rebuild any product or material contained on the site, remove any copyright or other restrictive documentations from the materials, or transfer the materials to someone else or "mirror" the materials on any other server.
            </p>
            <p>
               This license may be terminated if you disregard any of these restrictions and may be terminated by Solectic Engineering Solutions at any time. After license termination or when your viewing license is terminated, you must destroy any downloaded materials in your possession whether in electronic or printed form.
            </p>
            <p>
               We provide the materials on the Solectic Engineering Solutions site "as is". We do not provide any guarantees, expressed or implied, and thus renounce and nullify all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Furthermore, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on our Internet site or otherwise relating to such materials or on any sites linked to this website.
            </p>
            <p>
               In no event shall Solectic Engineering Solutions or its suppliers be liable for any damages, including without limitation, damages for loss of data or profit, or due to business interruption, arising out of the use or inability to use the materials on Solectic Engineering Solutions' Internet web page. Even if Solectic Engineering Solutions or a Solectic Engineering Solutions authorized representative has been notified orally or in writing of the possibility of such damage, we will not be held responsible. Since some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
            </p>
            <p>
               The materials appearing on Solectic Engineering Solutions' site may contain typographical or photographic errors. We do not warrant that any of the materials on the site are accurate, complete, or current. We may make changes to the materials contained on the site at any time without notice. We do not, however, make any commitment to update the materials.
            </p>
            <p>
               We have not reviewed all of the websites or links connected to our website and are not responsible for the content of any such linked site. The inclusion of any link does not imply endorsement by our site. Use of any such linked website is at the user's own risk.
            </p>
            <p>
               We reserve the right to update these terms of use for our website at any time without notice. By using this site, you agree to be bound by the then-current version of these Terms and Conditions of Use.
            </p>
            <p>
               These Terms and Conditions shall be governed by and construed in accordance with the laws of Pakistan, and you agree to submit to the non-exclusive jurisdiction of the courts in Islamabad for the resolution of any disputes.
            </p>
         </Container>
      </Box>
   )
}

export default TermsContent