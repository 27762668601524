import {
   Box,
   Button,
   ListItemButton,
   ListItemText,
   Toolbar,
   styled,
   Typography,
   keyframes
} from "@mui/material";


export const colors = {
   textColor: "#ffffff",
   color1: "#000000"
};

export const NavToolbar = styled(Toolbar)(() => ({
   display: "flex",
   flexFlow: "row",
   justifyContent: "space-between",
   alignItems: "center"
}));

export const pages = [
   { name: "Home", link: "/" },
   { name: "About us", link: "/about-us" },
   { name: "Residentials", link: "/residential" },
   { name: "Commercial", link: "/commercial" },
   { name: "Contact us", link: "/contact-us" },
   { name: "Projects", link: "/projects" },
];

export const MenuButton = styled(Button)(() => ({
   color: colors.textColor,
   fontFamily: "Regular",
   fontSize: "14px",
   fontWeight: "500",
   textTransform: 'capitalize'
}));

export const MenuListItemButton = styled(ListItemButton)(() => ({
   fontFamily: "Regular",
   borderBottom: "1px solid #d1d1d1",
   alignItems: "center",
   textAlign: "center",
   "& $lastChild": {
      borderBottom: "none"
   }
}));

export const MenuListItemText = styled(ListItemText)(() => ({
   fontFamily: "Regular",
   fontSize: "16px",
   fontWeight: "500"
}));

//Header Components & Css

export const Header_Box = styled(Box)(({ theme }) => ({
   width: "100%",
   [theme.breakpoints.up("md")]: {
      minHeight: "70vh",
      height: "auto",
      padding: "10px"
   },
   [theme.breakpoints.only("sm")]: {
      minHeight: "55vh",
      height: "auto",
      padding: "10px"
   },
   [theme.breakpoints.only("xs")]: {
      minHeight: "45vh",
      height: "auto",
      padding: "10px"
   },
   // background: `linear-gradient(to bottom right, #04303140,#00606473),url(${bannerImage})`,
   display: "flex",
   flexFlow: "column wrap",
   justifyContent: "center",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat"
}));

export const Header_buttons = styled(Box)(() => ({
   display: "flex",
   position: "relative",
   marginTop: "20px",
   "&:before": {
      content: "''",
      width: "40px",
      height: "40px",
      borderRadius: "50%"
   }
}));

export const Header_buttons_1 = styled(Typography)(() => ({
   textDecoration: "none",
   color: colors.textColor,
   cursor: "pointer",
   position: "absolute",
   left: "22px",
   lineHeight: "40px",
   fontFamily: "Regular",
   fontWeight: "bold"
}));

export const Header_buttons_2 = styled(Typography)(() => ({
   textDecoration: "none",
   color: colors.textColor,
   cursor: "pointer",
   position: "absolute",
   left: "100px",
   lineHeight: "50px",
   fontFamily: "Regular",
   fontWeight: "bold",
   animation: `${upDown} 1s infinite ease`
}));

const upDown = keyframes`
  0% {
    padding-top:0px;
    transform: scale(1,1);
    }
    100% {
      padding-top:10px;
      transform: scale(1,2);
    }
  `;

//Skill Section
