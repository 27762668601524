import { Grid, Box, Typography } from '@mui/material'
import React from 'react'

const Counter = () => {
  return (
    <React.Fragment>
       <div className='bgGrey mainPadding' >
            <Grid className='tt-counter' >
                <Box className='tt_flex_Counter' >
                <Box className='tt_inner_counter'><span className="count">150</span><span className='counter_inner'>+</span></Box>
                <Typography>Systems Installed</Typography>
                </Box>
                <Box className='tt_flex_Counter'>
                <Box className='tt_inner_counter'><span className="count">156</span><span className='counter_inner'>GWh</span></Box>
                <Typography>Solar Produced</Typography>
                </Box>
                <Box className='tt_flex_Counter'>
                <Box className='tt_inner_counter'><span className="count">10</span><span className='counter_inner'>kTonnes</span></Box>
                <Typography>CO2 Reduced</Typography>
                </Box>
            </Grid>
       </div>
    </React.Fragment>
  )
}

export default Counter