import { Box, Container, Grid } from "@mui/material";
import React from "react";
import SingleProjectGallery from "./SingleProjectGallery";
import { allProjects } from "../ProjectsData.js"

const IndustrialInner = () => {

   return (
      <React.Fragment>
         <Box>
            <Container maxWidth="xl" className="industrialinner">
               <Grid
                  className="tt_Logo_inner"
                  container
                  spacing={2}
                  sx={{ alignItems: { xs: "center", md: "center" } }}
               >
                  {
                     allProjects?.map(project => (
                        <SingleProjectGallery
                           key={project.id}
                           title={project.title}
                           region={project.region}
                           type={project.type}
                           size={project.size}
                           co2={project.co2}
                           images={project.images}
                        />
                     ))
                  }
               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   );
};

export default IndustrialInner;
