import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const ContactLocations = () => {

   const locationData = [
      {
         title: 'Islamabad Head Office',
         address: "Office 19 1st Floor Swiss Centre D12 Markaz, Islamabad",
         href: "https://www.google.com/maps/search/Office+19+1st+Floor+Swiss+Centre+D12+Markaz,+Islamabad/@33.7007243,72.9476148,17z?entry=ttu"
      },
      {
         title: 'Lahore Office',
         address: "Johar Town Lahore",
         href: "https://www.google.com/maps/place/Johar+Town,+Lahore,+Punjab,+Pakistan/@31.4646503,74.2357622,13z/data=!3m1!4b1!4m6!3m5!1s0x3919015f82b0b86f:0x2fcaf9fdeb3d02e6!8m2!3d31.469693!4d74.2728461!16zL20vMGdnNDlt?entry=ttu"
      }
   ]

   return (
      <React.Fragment>
         <Box>
            <Container maxWidth="xl" sx={{ mt: 5 }}>
               <Grid
                  container
                  spacing={3}
               >
                  {
                     locationData?.map((item, index) => (
                        <Grid item xs={12} md={6} key={index}>
                           <Box className='contactLocationBox'>
                              <Box>
                                 <Typography variant="h6" className='locationTitle'>{item?.title}</Typography>
                                 <Typography variant="p" className='locationAddress'>{item?.address}</Typography>
                              </Box>
                              <a href={item?.href} target='_blank' className='btnTrans'>GET DIRECTIONS</a>
                           </Box>
                        </Grid>
                     ))
                  }

               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   )
}

export default ContactLocations