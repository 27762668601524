import React, { useState } from 'react'
import BG from '../../Images/bg/4.jpg'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

const FirstContent = () => {

   const [open, setOpen] = useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const descriptionElementRef = React.useRef(null);
   React.useEffect(() => {
      if (open) {
         const { current: descriptionElement } = descriptionElementRef;
         if (descriptionElement !== null) {
            descriptionElement.focus();
         }
      }
   }, [open]);


   return (
      <React.Fragment>
         <Box className="solar-wrapper" sx={{ marginTop: "-2px" }}>
            <Box className="solar-wrapper-img">
               <img width="100%" src={BG} alt="commercial solar panel system cost" />
            </Box>
            <Box className="tt-solar-wrapper">
               <Typography variant="h2">COMMERCIAL</Typography>
               <Typography>
                  Embracing Challenges Amidst the Pandemic to Ensure a Smooth Path Toward a Sustainable Future
               </Typography>
               <Box className="tt-BtnMain">
                  <button className="ttBtn solarBtn" onClick={() => setOpen(true)}>Learn More</button>
               </Box>
            </Box>
         </Box>

         {/* Dialog */}
         <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            <DialogTitle id="scroll-dialog-title">COMMERCIAL</DialogTitle>
            <DialogContent dividers={true}>
               <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
               >
                  As we tend to write this, we tend to area units compact by the novel coronavirus and COVID-19, sheltering in situ and dealing remotely. Because the pandemic continues to remodel daily lives and business operations, a lot has been written concerning the impact on supply chains and makers. that has the availability of renewable energy instrumentation and technologies, like those utilized in star PV and energy storage systems. However, as keeping distributed energy generation and renewables running a swimmingly area unit essential to important infrastructure, we tend to area unit on duty. nonetheless it’s U.S. pondering what could stall planned build-out of latest comes. Even before COVID-19, the grid interconnection method slowed electrical phenomenon (PV) installations. Managing interconnection has continually been essential to avoid project delays. Understanding and securing money incentives relationship to government programs and utility rate tariffs is one of the foremost advanced aspects of interconnection.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Close</Button>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   )
}

export default FirstContent