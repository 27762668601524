import React, { useEffect } from 'react'
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer/Footer"
import ContactBanner from '../Components/ContactComponents/ContactBanner'
import ContactInfo from '../Components/ContactComponents/ContactInfo'
import ContactForm from '../Components/ContactComponents/ContactForm'
import ContactLocations from '../Components/ContactComponents/ContactLocations'
import CustomerSupport from '../Components/ContactComponents/CustomerSupport'
import ContactMap from '../Components/ContactComponents/ContactMap'
import ChatButtons from '../Components/ChatButtons'
import SEO from '../Components/Seo/Seocomponent'

export const Contact = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <>
      <SEO
      title="Solar Electric Contact Number| Solectic Engineering"
      description="Find here all about solar power installation and best electric companies of Pakistan, solar panel system for home and business use. Contact us at 0334-4859556 for free consultation."
      keywords="Solectic Contact Number,Solectic Contact Us"
      link="http://solecticengineering.com/contact-us"
      />
         {/* <Navbar /> */}
         <ContactBanner />
         <ContactInfo />
         <ContactForm />
         <ContactLocations />
         {/* <CustomerSupport /> */}
         <ContactMap />
         {/* <ChatButtons />
         <Footer /> */}
      </>
   )
}
