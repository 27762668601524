import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description ,keywords,link}) => {
   return (
      <Helmet>
         <title>{title}</title>
         <meta name="description" content={description} />
         <meta name="keywords" content={keywords} />
         <link rel="canonical" href={link} />
         <meta name="author" content="Solectic Engineering" />
         <meta name="publisher" content="Solectic Engineering" />
      </Helmet>
   );
};

export default SEO;
