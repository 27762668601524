import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Vision from "../../Images/OurVision.png";
const vision = () => {
  return (
    <React.Fragment>
      <Box className="mainPadding bgLight ">
        <Container maxWidth="md" className="vision">
          <Grid
            className="tt-mission"
            container
            spacing={2}
            sx={{ alignItems: { xs: "center", md: "center" } }}
          >
            <Grid item xs={12} md={2} className="missionCenter">
              <img src={Vision} alt="Top Solar Companies Pakistan" />
            </Grid>
            <Grid item xs={12} md={10} className="missionCenter">
              <Typography className="missionTitle" variant="h2" >
                Our Vision
              </Typography>
              <Typography variant="p">
                To make clean, reliable, and affordable energy available to the
                developing world through energy innovation.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default vision;
