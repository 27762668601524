import React, { useEffect } from "react";
import ProjBanner from "../Components/projectComponents/ProjBanner";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer/Footer";
import Overview from "../Components/projectComponents/Overview";
import Residential from "../Components/projectComponents/Residential";
import Industrial from "../Components/projectComponents/Industrial";
import ContactFooter from "../Components/ContactComponents/ContactFooter";
import CustomerSupport from "../Components/ContactComponents/CustomerSupport";
import ChatButtons from "../Components/ChatButtons";
import SEO from "../Components/Seo/Seocomponent";
const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title="Projects - Solectic Engineering"
        description="A complete solar panel project solution provider. Specialized in solar power plants and other renewable energy projects. We are based in Pakistan. Check out our solar panels system projects. Get a free quote for your project today!"
        keywords="Solar Panel System Price In Pakistan,
Solar System For Home Price In Pakistan,
Solar Panel Manufacturers In Pakistan
"
        link="https://solecticengineering.com/projects"
      />
      {/* <Navbar /> */}
      <ProjBanner />
      <Overview />
      <Residential />
      <Industrial />
      {/* <CustomerSupport />
         <ChatButtons />
         <Footer /> */}
    </>
  );
};

export default Projects;
