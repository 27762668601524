import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { allCitiesOptions } from '../FormData.js'
import { hearOptions } from '../FormData.js'
import { customerTypeOptions } from '../FormData.js'
import { maxBillOptions } from '../FormData.js'
import { ACOptions } from '../FormData.js'
import { propertySizeOptions } from '../FormData.js'
import { propertyTypeOptions } from '../FormData.js'
import SelectArrow from '../../Images/select-arrow.svg'

const ContactForm = () => {
  return (
    <React.Fragment>
      <Box className="contactForm bgLight">
        <Container maxWidth="xl" className="">
          <Box sx={{ textAlign: 'center' }}>
            <Typography className="mission_Title" sx={{ marginBottom: '10px' }} variant="h2">
              Request More Information
            </Typography>
            <Typography variant="p">
              Complete the below form and our team will contact you shortly.
            </Typography>
          </Box>

          <form>
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "26px" }}
            >
              {/* FirstName */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="firstName">First Name*:</label>
                <input type="text" id='firstName' name='firstName' />
              </Grid>

              {/* Lastname */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="lastName">Last Name*:</label>
                <input type="text" id='lastName' name='lastName' />
              </Grid>

              {/* Email */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="email">Email:</label>
                <input type="email" id='email' name='email' />
              </Grid>

              {/* Contact No */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="contactNo">Contact No:</label>
                <input type="text" id='contactNo' name='contactNo' />
              </Grid>

              {/* Company */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="company">Company:</label>
                <input type="text" id='company' name='company' />
              </Grid>

              {/* City */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="company">City:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="city" id="city">
                    <option value="none">-None-</option>
                    {allCitiesOptions?.map(city => <option key={city} value={city}>{city}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Hear */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="hear">How did you hear about us?*:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="hear" id="hear">
                    <option value="none">-None-</option>
                    {hearOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Customer Type */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="customerType">Customer Type*:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="customerType" id="customerType">
                    <option value="none">-None-</option>
                    {customerTypeOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Max Bil */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="maxBill">Maximum Summer Electricity Bill?:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="maxBill" id="maxBill">
                    <option value="none">-None-</option>
                    {maxBillOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Number of ACs */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="AC">How many Inverter AC do you want to run?:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="AC" id="AC">
                    <option value="none">-None-</option>
                    {ACOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Property Size */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="propertySize">Property Size:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..." />
                  <select name="propertySize" id="propertySize">
                    <option value="none">-None-</option>
                    {propertySizeOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

              {/* Property Type */}
              <Grid className='inputField' item xs={12} md={6}>
                <label htmlFor="propertyType">Property Type:</label>
                <Box className='selectWrapper'>
                  <img src={SelectArrow} alt="..."  />
                  <select name="propertyType" id="propertyType">
                    <option value="none">-None-</option>
                    {propertyTypeOptions?.map(item => <option key={item} value={item}>{item}</option>)}
                  </select>
                </Box>
              </Grid>

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
              <button type='submit' className='btnBlack'>REQUEST A MEETING</button>
            </Box>
          </form>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default ContactForm