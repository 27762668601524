import { Box } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const AboutBanner = () => {
  return (
    <React.Fragment>
      <Box className="bannerInner">
        <img src={About} width="100%" alt="best solar company in pakistan" />
        <Box className="tt-banerInner banner_inner">
          <h1>Green Innovations for Clean Energy
          </h1>
          {/* <Main btn='Residential'/> */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AboutBanner;
