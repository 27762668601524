import React, { useState } from "react";
// import Solar from "../../Images/bussiness_high.jpg";
import Solar from "../../Images/bg/2.jpeg";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

const MarketGlobe = () => {

   const [open, setOpen] = useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const descriptionElementRef = React.useRef(null);
   React.useEffect(() => {
      if (open) {
         const { current: descriptionElement } = descriptionElementRef;
         if (descriptionElement !== null) {
            descriptionElement.focus();
         }
      }
   }, [open]);

   return (
      <React.Fragment>
         <Box className="solar-wrapper">
            <Box className="solar-wrapper-img inverse">
               <img width="100%" src={Solar} alt="solar panel manufacturers in pakistan" />
            </Box>
            <Box className="tt-solar-wrapper tt-InnerSolar-Wrapper">
               <Typography variant="h2">Solar Panel System Price in Pakistan – At The Best Price in Pakistan!</Typography>
               <Typography>
                  You’ve come to the right place! We are proud to offer some of the best solar panel system prices in Pakistan. Not only do we have a wide selection of products, but our team is also here to help you choose the perfect system for your needs.
               </Typography>
               <Box className="tt-BtnMain">
                  <button className="ttBtn solarBtn" onClick={() => setOpen(true)}>Learn More</button>
               </Box>
            </Box>
         </Box>

         {/* Dialog */}
         <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            <DialogTitle id="scroll-dialog-title">Solar Panel System Price in Pakistan – At The Best Price in Pakistan!</DialogTitle>
            <DialogContent dividers={true}>
               <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
               >
                  You’ve come to the right place! We are proud to offer some of the best solar panel system prices in Pakistan. Not only do we have a wide selection of products, but our team is also here to help you choose the perfect system for your needs. Solar panel systems are a great way to save money on your energy bills and help the environment. We have a wide selection of solar panel systems to choose from, so you can find the perfect one for your home or business.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Close</Button>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   );
};

export default MarketGlobe;
