import React, { useState } from "react";
import {
   AppBar,
   Box,
   Container,
   IconButton,
   Drawer,

} from "@mui/material";
import {
   NavToolbar,
   pages,
   MenuButton,
   colors,
} from "./Navtoools";
import { Link } from "react-router-dom"
// import Logo from '../Images/logo.png'
import Logo from '../Images/selecto.png.png'
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const Navbar = () => {
   const [openMenu, setOpenMenu] = useState(false);
   return (
      <AppBar className="tt-MainNav">
         <Container maxWidth="2xl">
            <NavToolbar disableGutters>
               <Box>
                  <Link to="/" color={colors.textColor} >
                     <img className="small-Logo" src={Logo} alt="solectic engineering" />
                  </Link>
               </Box>
               <Box sx={{ display: { xs: "none", md: "flex", pb: 5 } }}>
                  {pages.map((page) => (
                     <MenuButton key={page.name} sx={{ fontSize: "16px", padding: "0" }}>
                        <Link to={page.link} style={{ padding: "8px 12px" }}>
                           {page.name}
                        </Link>
                     </MenuButton>
                  ))}
               </Box>
               <Box className="mblMenu"
                  sx={{
                     display: { xs: "flex", md: "none" },
                     alignItems: "center",
                     textAlign: "center"
                  }}
               >
                  <IconButton onClick={() => setOpenMenu(!openMenu)}>
                     <MenuOpenIcon
                        sx={{ fontSize: "25px", color: colors.textColor }}
                     />
                  </IconButton>
                  <Drawer
                     PaperProps={{
                        sx: {
                           backgroundColor: "rgba(0, 0, 0, 0.8)",
                           backdropFilter: "blur(3px)",
                           minWidth: "200px",
                           width: "40vw"
                        },
                     }}
                     anchor="left"
                     open={openMenu}
                     onClose={() => setOpenMenu(!openMenu)}
                  >
                     <Link
                        to="/"
                        sx={{
                           textAlign: "center",
                           color: colors.bgColors,
                           padding: "10px"
                        }}
                     >
                        <img src={Logo} className="small-Logo" alt="solectic engineering" />
                     </Link>
                     <Box className="mblNavLinks">
                        {pages?.map((page) => (
                           <Link to={page.link} key={page.name}>{page.name}</Link>
                        ))}
                     </Box>
                  </Drawer>
               </Box>
            </NavToolbar>
         </Container>
      </AppBar >
   );
};

export default Navbar;
