import React from "react";
import { Box, Grid, Container, MenuItem, Typography } from "@mui/material";
import Facebook from '../../Images/fb.svg'
import Twitter from '../../Images/twitter.svg'
import Linkedin from '../../Images/lin.svg'
import Insta from '../../Images/insta.svg'
import Youtube from '../../Images/youtube.svg'
import { Link } from "react-router-dom";

const Footer = () => {
   const page = [
      {
         pageName: 'Contact Us',
         pageLink: '/contact-us'
      },
      {
         pageName: 'About Us',
         pageLink: '/about-us'
      },
      {
         pageName: 'Terms & Conditions',
         pageLink: '/terms-and-conditions'
      },
      {
         pageName: 'Privacy Policy',
         pageLink: '/privacy'
      }
   ]
   const images = [
      {
         pageName: Insta,
         pageLink: 'https://www.instagram.com/solecticengineering/'
      },
      {
         pageName: Facebook,
         pageLink: 'https://www.facebook.com/SolecticEngineering'
      },
       {
         pageName: Linkedin,
         pageLink: 'https://www.linkedin.com/company/solectic-engineering/mycompany/?viewAsMember=true'
      },
      {
         pageName: Twitter,
         pageLink: 'https://twitter.com/Solectic414'
      },
     
      
   ]
   return (
      <Box className="tt-Footer" sx={{ paddingBlock: '6px' }}>
         <Container maxWidth="xl">
            <Grid sx={{ display: { xs: "flex", md: "flex" } }}>
               <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  {page.map((e, id) => (
                     <MenuItem key={id} style={{ padding: "0", margin: "0 5px" }}>
                        <Link className="tt-FooterLink" to={e.pageLink} style={{ padding: "8px 12px" }}>
                           {e.pageName}
                        </Link>
                     </MenuItem>
                  ))}
               </Box >
               <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex", }, justifyContent: { xs: 'center', md: "flex-end" } }}>
                  {images.map((e, id) => (
                     <a className="tt-FooterLink" href={e.pageLink} target="_blank" key={id}>
                        <MenuItem >
                           <Typography textAlign="center">
                              <img src={e.pageName} alt="footer logo"  />
                           </Typography>
                        </MenuItem>
                     </a>
                  ))}
               </Box>
            </Grid>
         </Container>
      </Box>

   );
};

export default Footer;
