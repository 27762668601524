import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../Images/bg/10.jpg";
import image2 from "../../Images/bg/11.jpg";
import image3 from "../../Images/bg/pages-banner.jpg";
import Main from "../Main";
import { Box, Typography } from "@mui/material";

const Banner = () => {
  // const NextArrow = ({ onClick }) => {
  //    return (
  //       <Box className="arrow next" onClick={onClick}>
  //          <img alt="..." src={image1} />
  //       </Box>
  //    );
  // };

  // const PrevArrow = ({ onClick }) => {
  //    return (
  //       <Box className="arrow prev" onClick={onClick}>
  //          <img alt="..." src={image1} />
  //       </Box>
  //    );
  // };
  // const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    useTransform: false,
    autoplaySpeed: 2000,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    centerMode: false,
    centerPadding: 0,
    nextArrow: "",
    prevArrow: "",
    beforeChange: (current, next) =>
      console.log("Before change:", current, next),
  };

  return (
    <React.Fragment>
      <Slider {...settings} style={{ marginBottom: "2px" }}>
        <Box>
          <Box className="bannerInner">
            <img
              src={image1}
              width="100%"
              alt="best solar companies in pakistan"
              className="slider-img"
            />
            <Box className="tt-banerInner">
              <Typography variant="h1">
                Smart Solar Solutions for Commercial & Industrial Sectors
              </Typography>
              <Typography>
                Get Energy Control of Your Business with Enhanced Operational
                Efficiency
              </Typography>
              <Main text="Commercial" to="commercial" />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="bannerInner">
            <img src={image2} width="100%" alt="Solar Companies in Islamabad" className="slider-img" />
            <Box className="tt-banerInner banner_inner">
              <h1>End Power Outages Forever !</h1>
              <p>
                Get Solectic Engineering Smart Solar & Never Lose Power Day or
                Night
              </p>
              <Main text="Residential" to="residentials" />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="bannerInner">
            <img src={image3} width="100%" alt="best solar company in pakistan" className="slider-img" />
            <Box className="tt-banerInner banner_inner">
              <h1>Contact Now</h1>
              <p>
                {" "}
                Contact us and get more information about Solectic and its
                services{" "}
              </p>
              <Main text="Contact us" to="contact" />
            </Box>
          </Box>
        </Box>
      </Slider>
    </React.Fragment>
  );
};

export default Banner;
