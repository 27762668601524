import React from 'react'
import whatsapp from '../Images/whatsapp-cta.png'
import messenger from '../Images/messenger-cta.png'

const ChatButtons = () => {
   return (
      <div className='chat-buttons'>
         <a href="https://wa.me/+923065659733">
            <img src={whatsapp} alt="Solectic Contact Number" />
         </a>
         <a href="https://m.me/557346128010533">
            <img src={messenger} alt="Solectic Contact Us" />
         </a>
      </div>
   )
}

export default ChatButtons