import React from 'react'
import { Link } from 'react-router-dom';

const Main = ({ text, to }) => {
  return (
    <Link to={to}>
      <button className='ttBtn'>{text}</button>
    </Link>
  )
}

export default Main;