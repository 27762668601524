import React, { useState } from "react";
import Solar from "../../Images/bg/3.jpg";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
const Solor = () => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Box className="solar-wrapper" sx={{marginBottom:"-1px"}}>
        <Box className="solar-wrapper-img">
          <img width="100%" src={Solar} alt="Solar energy company in Islamabad" />
        </Box>
        <Box className="tt-solar-wrapper">
          <Typography variant="h2">Solar System For Houses in Pakistan</Typography>
          <Typography>
          Solar System For Houses in Pakistan is a perfect addition to your house. It’s clean, quiet and energy efficient. Plus, it can help reduce your monthly electricity bills by up to 70%. You won’t find another product like it on the market today.
          </Typography>
          <Box className="tt-BtnMain">
            <button className="ttBtn solarBtn" onClick={() => setOpen(true)}>Learn More</button>
          </Box>
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Solar System For Houses in Pakistan</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            Solar System For Houses in Pakistan is a perfect addition to your house. It’s clean, quiet and energy efficient. Plus, it can help reduce your monthly electricity bills by up to 70%. You won’t find another product like it on the market today. It’s not just an amazing product but also an incredible experience you can have every day of your life. With our solar panel system, you will be able to save money while doing something good for the environment! Our panels are made from high-quality materials that ensure long-lasting performance in any climate or weather condition so they will last a lifetime with proper care and maintenance. We provide all installation services as well as warranties Contact us right away if interested in the solar system for houses in Pakistan!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Solor;
