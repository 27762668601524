import React, { useState } from "react";
// import Solar from "../../Images/comm_hig.jpg";
import Solar from "../../Images/bg/1.jpeg";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
const Solor = () => {

   const [open, setOpen] = useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const descriptionElementRef = React.useRef(null);
   React.useEffect(() => {
      if (open) {
         const { current: descriptionElement } = descriptionElementRef;
         if (descriptionElement !== null) {
            descriptionElement.focus();
         }
      }
   }, [open]);

   return (
      <React.Fragment>
         <Box className="solar-wrapper">
            <Box className="solar-wrapper-img">
               <img width="100%" src={Solar} alt="Top solar electric companies" />
            </Box>
            <Box className="tt-solar-wrapper">
               <Typography variant="h2">Are You Looking For the Best Solar Panel Installation Company?</Typography>
               <Typography>
                  Solectic Engineering is one of the best solar companies in Pakistan. We are a commercial solar panel installer and service provider based out of Islamabad Pakistan. Our team has years of experience installing residential, commercial and industrial systems across the country.          </Typography>
               <Box className="tt-BtnMain">
                  <button className='ttBtn solarBtn' onClick={() => setOpen(true)}>Learn More</button>
               </Box>
            </Box>
         </Box>

         {/* Dialog */}
         <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            <DialogTitle id="scroll-dialog-title">Are You Looking For the Best Solar Panel Installation Company?</DialogTitle>
            <DialogContent dividers={true}>
               <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
               >
                  Solectic Engineering is one of the best solar companies in Pakistan. We are a commercial solar panel installer and service provider based out of Islamabad Pakistan. Our team has years of experience installing residential, commercial and industrial systems across the country. With our expertise, we can help you find the right solution to meet your energy needs with clean renewable power from the sun! You’ll never have to worry about rising electricity costs again when you switch to clean renewable energy from Solectic Engineering today! Call us at (+92)306 5659733 or email solecticengineeringsolutions@gmail.com now for more information on how we can help save money while helping protect our environment by switching to green energy sources like solar panels! Contact us today for a free consultation on how we can install a system that will fit your budget and lifestyle perfectly!
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Close</Button>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   );
};

export default Solor;
