import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import CustomerSupportPic from '../../Images/solectic (2).png';
import WhatsApp from '../../Images/whatsApp.svg';
import Email from '../../Images/email-icon.svg';
import Address from '../../Images/address-icon.svg';

const CustomerSupport = () => {
   return (
      <React.Fragment>
         <Box className="contact-info" sx={{ marginTop: '40px', paddingBlock: '50px' }}>
            <Container maxWidth="xl">
               <Grid className="tt-mission" container spacing={2}>
                  <Grid item md={2} className="contact-info-img">
                     <img src={CustomerSupportPic} width="200" alt="Solectic Customer Service"  />
                     <Divider orientation="vertical" className='info-divider' />
                  </Grid>
                  <Grid item md={8} xs={12}>
                     <a href="mailto:info@solecticengineering.com" className='contact-info-email'>
                        <img src={Email} alt="Solectic Contact Email"  />
                        info@solecticengineering.com
                     </a>
                     <a href="https://wa.me/+923065659733" className="whatsapp-number">
                        <img src={WhatsApp} alt="Solectic Contact Phone Number " />
                        +923065659733
                     </a>
                     <a href="https://wa.me/+923344859556" className="whatsapp-number">
                        <img src={WhatsApp} alt="Solectic Service Hotline"  />
                        +923344859556
                     </a>
                     <Typography variant="p" className='contact-info-address'>
                        <img src={Address} alt="Solectic Customer Service"  />
                        Office 19 1st Floor Swiss Centre D12 Markaz, Islamabad
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   );
};

export default CustomerSupport;
