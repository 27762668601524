import { Box, Container, Typography } from "@mui/material";
import React from "react";
import IndustrialInner from "./IndustrialInner";

const Industrial = () => {
   return (
      <React.Fragment>
         <Box className="mainPadding">
            <Container maxWidth="md" className="">
               <Box className="tt-overview">
                  <Typography variant="h2">
                     Commercial and Industrial Projects
                  </Typography>
                  <Typography className="tt-pt">
                     Solectic Engineering provides customized Smart Solar Systems according to
                     the needs of different commercial and industrial sectors. Business
                     owners are switching to Solectic Engineering Smart Solar System to maximize
                     their work efficiency and productivity with lowest energy costs.
                     Our clients include.
                  </Typography>
               </Box>
            </Container>
            <IndustrialInner />
         </Box>
      </React.Fragment>
   );
};

export default Industrial;
