import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer/Footer";
import PrivacyBanner from "../Components/Privacy/PrivacyBanner";
import PrivacyContent from "../Components/Privacy/PrivacyContent";
import { Box } from "@mui/material";
import ChatButtons from "../Components/ChatButtons";

const Privacy = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <Box>
         {/* <Navbar /> */}
         <PrivacyBanner />
         <PrivacyContent />
         {/* <ChatButtons />
         <Footer /> */}
      </Box>
   )
}

export default Privacy