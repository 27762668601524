// pages/About.js
import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import AboutBanner from '../Components/AboutComponents/AboutBanner';
import Footer from '../Components/Footer/Footer';
import { Box } from '@mui/material';
import Misson from '../Components/AboutComponents/Misson';
import Vision from '../Components/AboutComponents/vision';
import NadeemAkram from '../Components/AboutComponents/NadeemAkram';
import CustomerSupport from '../Components/ContactComponents/CustomerSupport';
import ChatButtons from '../Components/ChatButtons';
//@ts-ignore
import SEO from '../Components/Seo/Seocomponent'; // Import the SEO component

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <SEO
        title="Smart Solar Solutions for Commercial & Industrial Sectors | Solectric Engineering"
        description="Solectric Engineering offers the best solar panel installation services in Pakistan. With years of experience, we provide residential, commercial, and industrial solar systems at the best prices. Choose us for high-quality solar solutions and a sustainable future."
        keywords="best solar company in pakistan,solar companies in islamabad"
        link="http://solecticengineering.com/about-us"
        />
      <Navbar />
      <AboutBanner />
      <Misson />
      <Vision />
      <NadeemAkram />
      {/* <CustomerSupport /> */}
      <ChatButtons />
      {/* <Footer /> */}
    </Box>
  );
};

export default About;
