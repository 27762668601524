import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Banner from '../Components/HomeComponents/Banner'
import Solor from '../Components/HomeComponents/Solor'
import SmartBusiness from '../Components/HomeComponents/SmartBusiness'
import SmartSolution from '../Components/HomeComponents/SmartSolution'
import Choose from '../Components/HomeComponents/Choose'
import Counter from '../Components/HomeComponents/Counter'
import MarketGlobe from '../Components/HomeComponents/MarketGlobe'
import Testimonials from '../Components/HomeComponents/Testimonials'
import { Box } from '@mui/material'
import Footer from '../Components/Footer/Footer'
import CustomerSupport from '../Components/ContactComponents/CustomerSupport'
import ChatButtons from '../Components/ChatButtons'
import SEO from '../Components/Seo/Seocomponent'

const Home = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <Box className='tt-Home'>
          <SEO
        title="Best Solar Companies in Pakistan | Solar Panel System Price in Pakistan"
        description="Find the best solar companies in Pakistan and solar panels systems in Pakistan to save more from your electricity bills. Get free quotes."
        keywords="solar companies in pakistan, solar panel system price in pakistan, solar panel system price in pakitan"
        link="http://solecticengineering.com/"
      />
         {/* <Navbar /> */}
         <Banner />
         <Solor />
         <SmartBusiness />
         <SmartSolution />
         <Counter />
         <Testimonials />
         <Choose />
         <MarketGlobe />
         {/* <CustomerSupport /> */}
         {/* <ChatButtons /> */}
         {/* <Footer /> */}
      </Box>

   )
}

export default Home