import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Overview = () => {
   return (
      <React.Fragment>
         <Box className="mainPadding">
            <Container maxWidth="md" className="">
               <Box className="tt-overview">
                  <Typography variant="h2" sx={{ marginBottom: "20px" }} className="mission_Title">Overview</Typography>
                  <Typography>
                     Solectic Engineering Smart Solar System is the World's Smartest Solar
                     System with in-built Artificial Intelligence-based software,
                     Battery management software and 24/7 customer care services. So
                     far, Solectic Engineering Pvt Ltd is the fastest-growing company in
                     Pakistan and has been successful in creating a footprint all over
                     Pakistan with clients from residential, commercial and industrial
                     segments.
                  </Typography>
               </Box>
            </Container>
         </Box>
      </React.Fragment>
   );
};

export default Overview;
