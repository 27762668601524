import { Box, Typography } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const PrivacyBanner = () => {
   return (
      <React.Fragment>
         <Box className="bannerInner">
            <img src={About} width="100%" alt="Privacy Policy" />
            <Box className="tt-banerInner banner_inner">
               <Typography variant="h1">
                  Our Privacy Policy
               </Typography>
            </Box>
         </Box>
      </React.Fragment>
   );
};

export default PrivacyBanner;
