import React, { useState } from "react";
import stars from "../../Images/stars.png";
import profile from "../../Images/user-placeholder.png";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";

const Testimonials = () => {
  // console.log(document.querySelector('.testimonial-slide .slick-current'))
  // const NextArrow = ({ onClick }) => {
  //    return (
  //       <Box className="arrow next" onClick={onClick}>
  //          <img alt="..." src={image1} />
  //       </Box>
  //    );
  // };

  // const PrevArrow = ({ onClick }) => {
  //    return (
  //       <Box className="arrow prev" onClick={onClick}>
  //          <img alt="..." src={image1} />
  //       </Box>
  //    );
  // };

  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    infinite: true,
    lazyLoad: true,
    autoPlay: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    centerMode: true,
    centerPadding: 0,
    nextArrow: "",
    prevArrow: "",
    beforeChange: (current, next) => setImageIndex(next),
  };
  return (
    <React.Fragment>
      <section className="testimonial-slide-sec">
        <div className="testimonial-slide">
          <Slider {...settings}>
            <section>
              <div
                className="testimonial-slide-item"
                style={{ textAlign: "center" }}
              >
                <img
                  src={stars}
                  alt="solar systems in pakistan"
                />
                <p class="mb-0" style={{ marginTop: "10px" }}>
                  <i>
                    I have used services from Solectric Engineering, they are
                    very professional and they have best solar systems. I
                    recommend everyone to use there services.{" "}
                  </i>
                </p>
                <img
                  src={profile}
                  style={{ marginTop: "16px" }}
                  alt="solar companies in Islamabad"
                />
                <h5 style={{ marginTop: "13px" }}>Unique Bhatti</h5>
              </div>
            </section>
            <section>
              <div
                className="testimonial-slide-item"
                style={{ textAlign: "center" }}
              >
                <img
                  src={stars}
                  alt="best solar company"
                />
                <p class="mb-0" style={{ marginTop: "10px" }}>
                  <i>
                    Excellent services for installing Solar panel. recommend for
                    everyone who wants to install{" "}
                  </i>
                </p>
                <img
                  src={profile}
                  style={{ marginTop: "16px" }}
                  alt="solar panel system price "
                />
                <h5 style={{ marginTop: "13px" }}>Nauman Abdul Qayyum</h5>
              </div>
            </section>
            <section>
              <div
                className="testimonial-slide-item"
                style={{ textAlign: "center" }}
              >
                <img
                  src={stars}
                  alt="Solar in Islamabad"
                />
                <p class="mb-0" style={{ marginTop: "10px" }}>
                  <i>
                    I recommend this to everyone in my circle to must try this
                    one . I am very happy 😊 with the installation and services{" "}
                  </i>
                </p>
                <img
                  src={profile}
                  style={{ marginTop: "16px" }}
                  alt="solar panel price in pakistan"
                />
                <h5 style={{ marginTop: "13px" }}>Abu Moosa </h5>
              </div>
            </section>
            <section>
              <div
                className="testimonial-slide-item"
                style={{ textAlign: "center" }}
              >
                <img src={stars}  alt="best solar in pakistan"/>
                <p class="mb-0" style={{ marginTop: "10px" }}>
                  <i>
                    Hi, I belong to okara city and got to know about their
                    services through my ISB friends and also got solar system
                    installation. Got Satisfaction in whole installation
                    scenarios. I recommend it, if you are looking for solar
                    system installation experts.{" "}
                  </i>
                </p>
                <Box>
                  <img src={profile}  alt="
	Solar energy companies in Islamabad
	"/>
                  <h5 style={{ marginTop: "13px" }}>Muhammad Bilal </h5>
                </Box>
              </div>
            </section>
          </Slider>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Testimonials;
