import { Box, Typography } from "@mui/material";
import React from "react";

const Choose = () => {
   return (
      <React.Fragment>
         <Box className="tt-Choose home-choose">
            <Box className="tt-choose-heading"><Typography variant="h2">Why Choose Us ?</Typography></Box>

            <Box className="choose">
               Selecting Solectric Engineering Solutions as your solar installation company means taking a step towards a brighter and sustainable future. Our unwavering commitment to high-quality solar solutions empowers you to make a positive impact on the environment while enjoying the benefits of renewable energy.
               It's essential to choose a solar installation company with a proven track record and extensive experience. We have successfully completed many projects demonstrate reliability and expertise. Quality components, high-efficiency panels, and customized solutions are non-negotiable factors that customers demand from our company.
            </Box>
         </Box>
      </React.Fragment>
   );
};

export default Choose;
