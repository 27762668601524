import { Box, Typography } from "@mui/material";
import React from "react";
import About from "../../Images/bg/pages-banner.jpg";

const Resbanner = () => {
   return (
      <React.Fragment>
         <Box className="bannerInner">
            <img src={About} width="100%" alt="Residential Solar Panels System" />
            <Box className="tt-banerInner banner_inner">
               <Typography variant="h1">
                  Solectic's Footprint on the Green Planet
               </Typography>
               <Typography>
                  Switch to Solar for Clean, Green and Affordable Energy
               </Typography>
            </Box>
         </Box>
      </React.Fragment>
   );
};

export default Resbanner;
