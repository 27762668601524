import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


const PrivacyContent = () => {
   return (
      <Box className="mainPadding privacy">
         <Container maxWidth="lg">
            <Typography variant="h2" className="mission_Title" sx={{ textAlign: "center" }}>
               Privacy Policy of Solectic Engineering Solutions (SMC-PVT) LTD.
            </Typography>

            <Typography variant='h3'>1. Introduction</Typography>
            <p>
               Solectic Engineering Solutions (SMC-PVT) LTD. is dedicated to safeguarding the privacy of our website visitors and service users. Our website, <Link to="/">https://www.solecticengineering.com/</Link>, offers Solar System Installation and Customer Care Services. This privacy policy outlines how we use and protect the information provided by website visitors and service users. We do not share non-public personal information with unaffiliated third parties, except with consent or in compliance with all applicable laws. Please review this policy carefully, as we reserve the right to amend it at any time. By utilizing our service, you agree with the collection and use of information in accordance with this policy. The personal information we collect is used for providing and enhancing the service. We will not use or disclose your information to anyone else, except as outlined in this Privacy Policy. The terms used in this Privacy Policy have the same meaning as in our Terms and Conditions, which are accessible at <Link to="/terms-and-conditions">https://www.solecticengineering.com//terms-and-conditions.</Link>
            </p>

            <Typography variant='h3'>2. Information Collection and Use</Typography>
            <p>
               To enhance user experience, we may request certain personally identifiable information, such as your name, phone number, email address, and postal address. We use this information to contact and identify you.
            </p>

            <Typography variant='h3'>3. Log Data</Typography>
            <p>
               We gather Log Data, which is information that your browser transmits to us, whenever you utilize our service. This information may include your computer's internet protocol ("IP") address, geographical location, browser type, referral source, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
            </p>

            <Typography variant='h3'>4. Cookies</Typography>
            <p>
               Cookies are small data files that are typically used as anonymous unique identifiers. Our website employs cookies to gather information that helps us improve our service. You have the option to accept or decline these cookies. If you decide to decline our cookies, certain portions of our service may not be accessible.
            </p>

            <Typography variant='h3'>5. Service Providers</Typography>
            <p>
               We may contract third-party companies and individuals for various reasons, such as to facilitate our service, provide service on our behalf, carry out service-related activities, or assist us in analyzing how our service is used. These third parties may have access to your personal information, but they are bound not to use or disclose it for any other purpose.
            </p>

            <Typography variant='h3'>6. Security</Typography>
            <p>
               We value your trust and are committed to protecting your personal information. We employ commercially acceptable means to secure it. However, please note that no method of transmission over the internet or electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
            </p>

            <Typography variant='h3'>7. Links to Other Sites</Typography>
            <p>
               Our website may contain links to other sites. Please note that we do not operate these external sites, and we are not responsible for their content, privacy policy, or practices. We strongly encourage you to review the privacy policy of these external sites and services.
            </p>

            <Typography variant='h3'>8. Changes to This Privacy Policy</Typography>
            <p>
               We reserve the right to update our Privacy Policy from time to time. We will notify you of any changes by posting the most recent version on this page. These changes will be effective immediately.
            </p>

            <Typography variant='h3'>9. Contact Us</Typography>
            <p>
               If you have any questions or suggestions regarding our Privacy Policy, please do not hesitate to contact us at <a href="tel:+923344859550">+92 334 4859550</a> or <a href="mailto:info@solecticengineering.com"> info@solecticengineering.com</a>.
            </p>
         </Container>
      </Box>
   )
}

export default PrivacyContent