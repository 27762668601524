import React, { useState } from 'react'
import BG from '../../Images/bg/5.jpg'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

const FirstContent = () => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <React.Fragment>
      <Box className="solar-wrapper">
        <Box className="solar-wrapper-img inverse">
          <img width="100%" src={BG} alt="Commercial Solar Panels" />
        </Box>
        <Box className="tt-solar-wrapper tt-InnerSolar-Wrapper">
          <Typography variant="h2">FINANCIAL INCENTIVES SUPPORT SUNNY FORECAST FOR SOLAR </Typography>
          <Typography>
            From federal phases to local opportunities, we simplify your path to sustainable energy investment.
          </Typography>
          <Box className="tt-BtnMain">
            <button className="ttBtn solarBtn" onClick={() => setOpen(true)}>Learn More</button>
          </Box>
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">FINANCIAL INCENTIVES SUPPORT SUNNY FORECAST FOR SOLAR </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            At the United States federal level, the star Investment decrease (ITC), is within the method of phasing out or being revised. Where things get a lot of sophisticated is at the state and native level. money incentives by state and native utility vary wide and often amendment. for instance, recent information from the National Renewable Energy Laboratory (NREL) highlights the range, and ensuing complexness, of fixing incentives, which might include: Solar renewable energy certificates or SRECs SRECs ar on the market from a minimum of eighteen states and Washington, D.C. that have Renewable Portfolio commonplace (RPS) or “solar carve-out” provisions for its businesses and residents. SRECs may be bought and sold in spot markets or long sales, serving to to offset the proactive investment to assist shift United States quicker to property energy infrastructures. State and native tax credits Example: star PV systems ar exempt from property taxes in a minimum of twenty six states and Washington, D.C. See a a lot of sturdy list during this article on incentives. Credit sharing Renewable Energy Self-Generation Bill Credit Transfer (RES-BCT) permits native governments and universities to share generation credits from a system situated on one government-owned property with asking accounts at alternative government closely-held properties. The system size limit beneath RES-BCT is five MW. Bill credits ar applied at the generation-only portion of a customer’s retail rate. RES-BCT permits native governments and universities to share generation credits from a system situated on one government-owned property with asking accounts at alternative government closely-held properties. the scale limit for systems mistreatment RES-BCT is five MW. Production-based incentives By far the foremost generally adopted and arguably only suggests that to incentivize personal and company investment in renewables has been internet energy metering (NEM) or internet metering.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default FirstContent